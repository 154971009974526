// https://ionicframework.com/docs/theming/themes

:root {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255,255,255;

  --ion-text-color: #987001;
  --ion-text-color-rgb: 152,112,1;

  --ion-color-step-50: #faf8f2;
  --ion-color-step-100: #f5f1e6;
  --ion-color-step-150: #f0ead9;
  --ion-color-step-200: #eae2cc;
  --ion-color-step-250: #e5dbc0;
  --ion-color-step-300: #e0d4b3;
  --ion-color-step-350: #dbcda6;
  --ion-color-step-400: #d6c699;
  --ion-color-step-450: #d1bf8d;
  --ion-color-step-500: #ccb880;
  --ion-color-step-550: #c6b073;
  --ion-color-step-600: #c1a967;
  --ion-color-step-650: #bca25a;
  --ion-color-step-700: #b79b4d;
  --ion-color-step-750: #b29441;
  --ion-color-step-800: #ad8d34;
  --ion-color-step-850: #a78527;
  --ion-color-step-900: #a27e1a;
  --ion-color-step-950: #9d770e;
}
